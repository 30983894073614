import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProcessSteps from "./ProcessSteps";
import ProcessFeedback from "./ProcessFeedback";

function App() {
  return (
    <Router>
      <div className="App">
        <main>
          <Routes>
            {/* Use "element" to pass the component in v6 */}
            <Route path="/feedback" element={<ProcessFeedback />} />
            <Route path="/" element={<ProcessSteps />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
