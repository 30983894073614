import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";

const ProcessSummary = () => {
  const [eventData, setEventData] = useState(null);
  const [feedbacks, setFeedbacks] = useState({});
  const [microtaskFeedbacks, setMicrotaskFeedbacks] = useState({});
  const [proposedTasks, setProposedTasks] = useState({});
  const [newTaskByStep, setNewTaskByStep] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const event_id = queryParams.get("Event_id");

    // Fetch the event data
    const fetchEventData = async () => {
      try {
        const response = await axios.get(
          `http://62.103.245.63:18004/get_event_data?event_id=${event_id}`
        );
        setEventData(response.data);
        if (response.data.feedback_given === true) {
          setIsSaveDisabled(true); // Disable button if process is already completed
        }
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };

    fetchEventData();
  }, []);

  // Format step number properly with leading zero for single digits
  const formatStepNumber = (stepId) => {
    return stepId < 10 ? `0${stepId}` : stepId;
  };

  // Handle input change for feedback
  const handleFeedbackChange = (stepId, feedback) => {
    setFeedbacks({
      ...feedbacks,
      [stepId]: feedback,
    });
  };

  // Handle radio button selection for microtask feedback
  const handleMicrotaskFeedbackChange = (stepId, microtaskId, feedback) => {
    setMicrotaskFeedbacks({
      ...microtaskFeedbacks,
      [stepId]: {
        ...microtaskFeedbacks[stepId],
        [microtaskId]: feedback,
      },
    });
  };

  // Handle input change for proposed task
  const handleTaskInputChange = (stepId, value) => {
    setNewTaskByStep((prevState) => ({
      ...prevState,
      [stepId]: value,
    }));
  };

  // Add proposed task to in-memory state (without saving yet)
  const handleAddTask = (stepId) => {
    const newTask = newTaskByStep[stepId] || "";
    if (!newTask.trim()) return; // Skip if empty

    // Accumulate the tasks in the proposedTasks state for UI display
    const updatedProposedTasks = {
      ...proposedTasks,
      [stepId]: [...(proposedTasks[stepId] || []), newTask], // Append the new task
    };

    setProposedTasks(updatedProposedTasks);

    // Clear input for the step after adding the task
    setNewTaskByStep((prevState) => ({
      ...prevState,
      [stepId]: "", // Reset input field
    }));
  };

  const handleSave = async () => {
    try {
      const updatedSteps = eventData.steps.map((step) => ({
        ...step,
        step_execution_feedback: feedbacks.hasOwnProperty(step.stepId)
          ? feedbacks[step.stepId] || "" // Use the feedback if exists, or an empty string if cleared
          : step.step_execution_feedback, // Retain the existing feedback if no change was made
        microtasks: step.microtasks.map((microtask) => ({
          ...microtask,
          microtask_offline_feedback:
            microtaskFeedbacks[step.stepId] &&
            microtaskFeedbacks[step.stepId][microtask.microtaskId]
              ? microtaskFeedbacks[step.stepId][microtask.microtaskId]
              : microtask.microtask_offline_feedback || "", // Retain the existing feedback or set as empty
        })),
        // Add proposed tasks
        proposed_sub_tasks: proposedTasks[step.stepId]
          ? proposedTasks[step.stepId] // Use the new proposed tasks if they exist
          : step.proposed_sub_tasks || [], // Retain existing proposed tasks or initialize as empty
      }));

      const updatedEventData = {
        ...eventData,
        steps: updatedSteps,
        feedback_given: true, // Set feedback_given at the root level
      };

      const response = await axios.post(
        "http://62.103.245.63:18004/update_event_in_history",
        updatedEventData
      );
      console.log("Feedback saved:", response.data);
      alert("Feedback saved successfully.");
      setIsSaveDisabled(true);
    } catch (error) {
      console.error("Error saving feedback:", error);
    }
  };

  return (
    <div className="process-summary">
      <h1 className="process-title">Threat Management</h1>

      {/* Event Information Container */}
      <div className="event-info-box">
        <h2>Event Information</h2>
        <div className="event-id-box">
          {eventData ? (
            <p>
              <strong>Event ID:</strong> {eventData.eventID}
            </p>
          ) : (
            <p>Loading event...</p>
          )}
        </div>
      </div>

      {/* Common Process Summary */}
      <div className="process-summary-box">
        <h2>Common Process Summary</h2>
        <div className="process-timeline">
          {eventData ? (
            <div>
              <p>
                {eventData.processStartedTimestamp
                  ? new Date(
                      eventData.processStartedTimestamp
                    ).toLocaleTimeString()
                  : "00:00:00"}{" "}
                User Started Common Process Execution
              </p>

              {eventData.steps &&
                eventData.steps.map((step, index) => (
                  <div key={index} className="step-summary">
                    <h4>
                      Step {formatStepNumber(step.stepId)} Execution Overview -{" "}
                      {step.stepTitle}
                    </h4>
                    <div className="step-overview">
                      <p>
                        Started at{" "}
                        {step.completionTimestamp
                          ? new Date(
                              step.completionTimestamp
                            ).toLocaleTimeString()
                          : "00:00:00"}
                      </p>
                      {(step.microtasks || []).map((subTask, subIndex) => (
                        <p key={subIndex}>
                          {subTask.title} {"->"}{" "}
                          {subTask.completionState === 1
                            ? `Executed at ${
                                subTask.completionTimestamp
                                  ? new Date(
                                      subTask.completionTimestamp
                                    ).toLocaleTimeString()
                                  : "00:00:00"
                              }`
                            : "Not Executed"}
                        </p>
                      ))}
                      <p>
                        Completed (or Abandoned) at{" "}
                        {step.completionTimestamp
                          ? new Date(
                              step.completionTimestamp
                            ).toLocaleTimeString()
                          : "00:00:00"}
                      </p>
                      <p>
                        Short Description of Events:{" "}
                        {step.comment || "No Description"}
                      </p>
                      <p>
                        Success Evaluation:{" "}
                        <span
                          className={
                            step.evaluation === "Success"
                              ? "evaluation-success"
                              : step.evaluation === "Minor Deviation"
                              ? "evaluation-minor-deviation"
                              : step.evaluation === "Significant Deviation"
                              ? "evaluation-significant-deviation"
                              : "evaluation-failure"
                          }
                        >
                          {step.evaluation || "No Evaluation"}
                        </span>
                      </p>
                    </div>
                  </div>
                ))}
              <p className="process-ended">
                {eventData.processCompletedTimestamp
                  ? new Date(
                      eventData.processCompletedTimestamp
                    ).toLocaleTimeString()
                  : "00:00:00"}{" "}
                <b>Common Process</b> Execution Ended
              </p>
            </div>
          ) : (
            <p>Loading process summary...</p>
          )}
        </div>
      </div>
      <div className="feedback-box">
        {/* STEP Execution Feedback header (centered and shown only once) */}
        <h3 className="feedback-header">STEP Execution Feedback</h3>

        {eventData && eventData.steps && eventData.steps.length > 0 ? (
          eventData.steps.map((step, index) => (
            <div key={index} className="feedback-form">
              {/* Task Result */}
              <label className="task-result-label">
                STEP RESULT:{" "}
                <span
                  className={
                    step.evaluation === "Success"
                      ? "evaluation-success"
                      : step.evaluation === "Minor Deviation"
                      ? "evaluation-minor-deviation"
                      : step.evaluation === "Significant Deviation"
                      ? "evaluation-significant-deviation"
                      : "evaluation-failure"
                  }
                >
                  {step.evaluation || "No Evaluation"}
                </span>
              </label>

              {/* User feedback textarea for this task */}
              <textarea
                placeholder="Enter your feedback here..."
                value={feedbacks[step.stepId] || ""} // Prepopulate if there's existing feedback
                onChange={(e) =>
                  handleFeedbackChange(step.stepId, e.target.value)
                } // Handle feedback change
                className="feedback-input"
              />
            </div>
          ))
        ) : (
          <p>No steps available to display.</p>
        )}
      </div>
      {eventData && eventData.steps && eventData.steps.length > 0 ? (
        eventData.steps.map((step, index) => (
          <div key={index} className="feedback-box">
            {/* Step Title */}
            <h3 className="feedback-header">
              STEP {formatStepNumber(step.stepId)} Feedback
            </h3>

            {/* Subtasks */}
            {step.microtasks.map((microtask, idx) => (
              <div key={idx} className="subtask-feedback">
                <input
                  type="text"
                  value={microtask.title}
                  readOnly
                  className="feedback-input"
                />

                {/* Radio Buttons for microtask feedback */}
                <div className="radio-buttons">
                  <label>
                    <input
                      type="radio"
                      name={`radio-${step.stepId}-${microtask.microtaskId}`}
                      checked={
                        (microtaskFeedbacks[step.stepId] &&
                          microtaskFeedbacks[step.stepId][
                            microtask.microtaskId
                          ] === "Not Applicable") ||
                        false // Ensure a defined boolean value
                      }
                      onChange={() =>
                        handleMicrotaskFeedbackChange(
                          step.stepId,
                          microtask.microtaskId,
                          "Not Applicable"
                        )
                      }
                    />
                    Not Applicable
                  </label>
                  <label>
                    <input
                      type="radio"
                      name={`radio-${step.stepId}-${microtask.microtaskId}`}
                      checked={
                        (microtaskFeedbacks[step.stepId] &&
                          microtaskFeedbacks[step.stepId][
                            microtask.microtaskId
                          ] === "Inaccurate") ||
                        false
                      }
                      onChange={() =>
                        handleMicrotaskFeedbackChange(
                          step.stepId,
                          microtask.microtaskId,
                          "Inaccurate"
                        )
                      }
                    />
                    Inaccurate
                  </label>
                  <label>
                    <input
                      type="radio"
                      name={`radio-${step.stepId}-${microtask.microtaskId}`}
                      checked={
                        (microtaskFeedbacks[step.stepId] &&
                          microtaskFeedbacks[step.stepId][
                            microtask.microtaskId
                          ] === "Insignificant") ||
                        false
                      }
                      onChange={() =>
                        handleMicrotaskFeedbackChange(
                          step.stepId,
                          microtask.microtaskId,
                          "Insignificant"
                        )
                      }
                    />
                    Insignificant
                  </label>
                  <label>
                    <input
                      type="radio"
                      name={`radio-${step.stepId}-${microtask.microtaskId}`}
                      checked={
                        (microtaskFeedbacks[step.stepId] &&
                          microtaskFeedbacks[step.stepId][
                            microtask.microtaskId
                          ] === "Helpful") ||
                        false
                      }
                      onChange={() =>
                        handleMicrotaskFeedbackChange(
                          step.stepId,
                          microtask.microtaskId,
                          "Helpful"
                        )
                      }
                    />
                    Helpful
                  </label>
                  <label>
                    <input
                      type="radio"
                      name={`radio-${step.stepId}-${microtask.microtaskId}`}
                      checked={
                        (microtaskFeedbacks[step.stepId] &&
                          microtaskFeedbacks[step.stepId][
                            microtask.microtaskId
                          ] === "Essential") ||
                        false
                      }
                      onChange={() =>
                        handleMicrotaskFeedbackChange(
                          step.stepId,
                          microtask.microtaskId,
                          "Essential"
                        )
                      }
                    />
                    Essential
                  </label>
                </div>
              </div>
            ))}

            {/* Propose Sub-task Field */}
            <div className="propose-subtask">
              <label>Propose sub-task(s): {"    "}</label>
              <input
                type="text"
                value={newTaskByStep[step.stepId] || ""}
                onChange={(e) =>
                  handleTaskInputChange(step.stepId, e.target.value)
                }
                placeholder="Enter a new sub-task"
                className="propose-input"
              />
              <button
                className="propose-add-button"
                onClick={() => handleAddTask(step.stepId)}
              >
                ADD
              </button>
            </div>
          </div>
        ))
      ) : (
        <p>No steps available to display.</p>
      )}

      <div className="save-button-container">
        <button
          className="save-button"
          onClick={handleSave}
          disabled={isSaveDisabled}
          style={{
            backgroundColor: isSaveDisabled ? "gray" : "#007bff",
            cursor: isSaveDisabled ? "not-allowed" : "pointer",
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ProcessSummary;
