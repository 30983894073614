import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";

const ProcessSteps = () => {
  const [isProcessCompleted, setIsProcessCompleted] = useState(false); // Track whether the process is completed
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(null);
  // eslint-disable-next-line
  const [selectedSubTasks, setSelectedSubTasks] = useState({});
  const [completedSteps, setCompletedSteps] = useState([]);
  const [evaluation, setEvaluation] = useState(""); // Success Evaluation
  const [comment, setComment] = useState(""); // User Comments
  const [eventData, setEventData] = useState({}); // Store event data (process)
  // eslint-disable-next-line
  const [processStartedTimestamp, setProcessStartedTimestamp] = useState(""); // Process start time
  // eslint-disable-next-line
  const [processCompletedTimestamp, setProcessCompletedTimestamp] =
    useState(""); // Process completion time
  const [threatName, setThreatName] = useState(""); // NEW: State for threat name

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const threat_name = queryParams.get("threat_name");
    const event_id = queryParams.get("Event_id");
    const class_type = queryParams.get("class");
    const confidence = queryParams.get("confidence");
    const likelihood = queryParams.get("likelihood");
    const vulnerability = queryParams.get("vulnerability");

    setThreatName(threat_name); // NEW: Set the threat name from the query param
    const fetchData = async () => {
      try {
        // Call the external API with the required body format
        const response = await axios.post(
         `http://62.103.245.63:18004/check_or_create_event_internal`,
         // `http://backend:8800/check_or_create_event_internal`,
         // `http://localhost:18004/check_or_create_event_internal`,
        // `http://backend/check_or_create_event_internal`,
          {
            event_id: event_id,
            class_type: class_type,
            infoFromRisk: {
              threatName: threat_name,
              confidence: confidence,
              likelihood: likelihood,
              vulnerability: vulnerability,
            },
          }
        );
        
        // Set the steps state with the data received from the external API
        setSteps(response.data.steps || []); // Ensure steps is an array
        setEventData(response.data); // Store the entire event data

        // If process start timestamp does not exist, set it
        if (!response.data.processStartedTimestamp) {
          const processStartedTimestamp = new Date().toISOString();
          setProcessStartedTimestamp(processStartedTimestamp);
          updateLocalState({
            ...response.data,
            processStartedTimestamp, // Save process start timestamp
          });
        } else {
          setProcessStartedTimestamp(response.data.processStartedTimestamp);
        }
        if (response.data.processCompletedTimestamp) {
          setIsProcessCompleted(true); // Disable button if process is already completed
        }
      } catch (error) {
        console.error("Error fetching process steps:", error);
      }
    };

    fetchData();
  }, []);

  const updateLocalState = (updatedEvent) => {
    // Update the state locally
    setEventData(updatedEvent);

    // Send the updated JSON to the backend for persistence in MongoDB
    axios
      .post("http://62.103.245.63:18004/update_event_in_history", updatedEvent)
      .then((response) => {
        console.log("Updated event saved in history:", response.data);
      })
      .catch((error) => {
        console.error("Error updating event in history:", error);
      });
  };

  // Format step number properly with leading zero for single digits
  const formatStepNumber = (stepId) => {
    return stepId < 10 ? `0${stepId}` : stepId;
  };

  const handleStartClick = (stepId) => {
    setActiveStep(stepId); // Set the active step for subtask selection

    // Ensure that when a step is clicked, it opens the subtask selection UI
    const updatedSteps = steps.map((step) =>
      step.stepId === stepId
        ? { ...step, stepState: "Initiated" } // Set the step as Initiated
        : step
    );

    setSteps(updatedSteps);

    const updatedEvent = {
      ...eventData,
      steps: updatedSteps,
    };

    updateLocalState(updatedEvent); // Update backend with new state
  };

  const handleCompleteProcess = () => {
    if (isProcessCompleted) return; // Prevent multiple clicks if already completed

    const processCompletedTimestamp = new Date().toISOString();

    // Add processCompletedTimestamp to the event data
    const updatedEvent = {
      ...eventData,
      processCompletedTimestamp, // Add the completion timestamp to the event data
    };

    updateLocalState(updatedEvent); // Update the backend with the final process completion
    alert("Process completed and saved successfully.");

    // Set the process as completed and disable the button
    setIsProcessCompleted(true);
  };

  const handleSubTaskClick = (stepId, subTaskId) => {
    const currentTimestamp = new Date().toISOString();

    setSelectedSubTasks((prevSelected) => {
      const currentStep = prevSelected[stepId] || [];
      const step = steps.find((step) => step.stepId === stepId);
      const subTask = step.microtasks.find(
        (subTask) => subTask.microtaskId === subTaskId
      );
      const isSelected = subTask.completionState === 1; // Check if subtask is already executed (state 1)

      const updatedSubTasks = isSelected
        ? currentStep.filter((id) => id !== subTaskId) // Unselect
        : [...currentStep, subTaskId]; // Select

      // Update the subtask state within the steps
      const updatedSteps = steps.map((step) =>
        step.stepId === stepId
          ? {
              ...step,
              microtasks: step.microtasks.map((subTask) =>
                subTask.microtaskId === subTaskId
                  ? {
                      ...subTask,
                      completionState: isSelected ? 0 : 1, // Toggle completionState
                      completionTimestamp: isSelected ? null : currentTimestamp, // Toggle timestamp
                      status: isSelected ? "Not started" : "Executed", // Toggle status
                    }
                  : subTask
              ),
            }
          : step
      );

      setSteps(updatedSteps);

      // Update the event data with the subtask completion state
      const updatedEvent = {
        ...eventData,
        steps: updatedSteps,
      };
      updateLocalState(updatedEvent); // Call function to update state and save to backend

      return {
        ...prevSelected,
        [stepId]: updatedSubTasks,
      };
    });
  };

  const handleAbandonClick = (stepId) => {
    const currentTimestamp = new Date().toISOString();

    // Update the step to be in "Abandoned" state
    const updatedSteps = steps.map((step) =>
      step.stepId === activeStep
        ? {
            ...step,
            stepState: "Abandoned", // Set the step state to "Abandoned"
            completionTimestamp: currentTimestamp, // Save abandonment timestamp
          }
        : step
    );

    setCompletedSteps([...completedSteps, activeStep]);
    setSteps(updatedSteps);
    setActiveStep(null);

    // Update the event data with the abandoned state
    const updatedEvent = {
      ...eventData,
      steps: updatedSteps,
    };
    updateLocalState(updatedEvent); // Call function to update state and save to backend
  };

  const handleCompleteClick = (stepId) => {
    const currentTimestamp = new Date().toISOString();

    // Update the step to be in "Completed" state
    const updatedSteps = steps.map((step) =>
      step.stepId === stepId
        ? {
            ...step,
            stepState: "Completed", // Set the step state to "Completed"
            completionTimestamp: currentTimestamp, // Save completion timestamp
          }
        : step
    );

    setCompletedSteps([...completedSteps, activeStep]);
    setSteps(updatedSteps); // Update the steps with the completed state
    setActiveStep(stepId); // Keep this step active to show the Microtask Overview UI
    setComment("");

    const updatedEvent = {
      ...eventData,
      steps: updatedSteps,
    };

    updateLocalState(updatedEvent); // Update backend with the new state
  };

  const handleEvaluationChange = (e) => {
    setEvaluation(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleFinalCompleteClick = (stepId) => {
    // Update the step to be in "Completed" state
    const updatedSteps = steps.map((step) =>
      step.stepId === activeStep
        ? {
            ...step,
            comment, // Save any user comments
            evaluation, // Save any evaluation if present
          }
        : step
    );

    setSteps(updatedSteps);
    setActiveStep(null);

    // Update the event data with the abandoned state
    const updatedEvent = {
      ...eventData,
      steps: updatedSteps,
    };
    updateLocalState(updatedEvent); // Call function to update state and save to backend
  };

  const isCompleteButtonDisabled = !evaluation;

  return (
    <div className="process-steps">
      <h1>Threat Management</h1>
      <h2>Type of Threat: {threatName}</h2>
      {(steps || []).map((step) => (
        <div
          key={step.stepId}
          className={`step-card ${activeStep === step.stepId ? "active" : ""} ${
            completedSteps.includes(step.stepId) ? "completed" : ""
          }`}
        >
          <div className="step-header">
            <p
              className={`step-number ${
                activeStep === step.stepId ? "in-progress" : ""
              }`}
            >
              Step {formatStepNumber(step.stepId)}
              {activeStep === step.stepId && " - In Progress"}
            </p>
          </div>

          {/* Display the step content (title, recommendations, etc.) */}
          <div className="step-content">
            <input
              type="text"
              value={step.stepTitle}
              readOnly
              className="step-title"
            />
            {step.stepRecommendation && (
              <p
                className={`step-recommendation ${
                  step.stepRecommendation.includes("Attention")
                    ? "red"
                    : "green"
                }`}
              >
                {step.stepRecommendation}
              </p>
            )}
            {/* Disable the Start button if the step is completed or abandoned */}
            <button
              className={`start-button ${
                step.stepState === "Completed" || step.stepState === "Abandoned"
                  ? "button-disabled"
                  : ""
              }`}
              onClick={() => handleStartClick(step.stepId)}
              disabled={
                step.stepState === "Completed" || step.stepState === "Abandoned"
              }
            >
              START
            </button>
          </div>

          {/* Show the subtask selection UI if the step is "Initiated" and not yet completed */}
          {activeStep === step.stepId && step.stepState !== "Completed" && (
            <div className="step-expanded-content">
              <div className="sub-tasks-container">
                {(step.microtasks || []).length > 0 ? (
                  step.microtasks.map((subTask, index) => (
                    <div key={subTask.microtaskId}>
                      <button
                        className={`sub-task-button ${
                          subTask.completionState === 1
                            ? "selected"
                            : "unselected"
                        }`}
                        onClick={() =>
                          handleSubTaskClick(step.stepId, subTask.microtaskId)
                        }
                      >
                        {subTask.microtaskId}. {subTask.title}{" "}
                        {/* Display microtask title */}
                      </button>
                    </div>
                  ))
                ) : (
                  <p className="no-subtasks">No sub-tasks available</p>
                )}
              </div>
              <div className="action-buttons">
                <button
                  className="abandon-button"
                  onClick={() => handleAbandonClick(step.stepId)}
                >
                  Abandon
                </button>
                <button
                  className="complete-button"
                  onClick={() => handleCompleteClick(step.stepId)}
                >
                  Complete
                </button>
              </div>
            </div>
          )}

          {/* Show the Microtask Overview when the step is "Completed" */}
          {activeStep === step.stepId && step.stepState === "Completed" && (
            <div className="completion-review">
              <h3>Step Execution Termination</h3>
              <p>{step.stepTitle}</p>
              <div className="sub-tasks-review">
                <h4>Microtask Execution Overview</h4>
                {(step.microtasks || []).map((subTask) => (
                  <div key={subTask.microtaskId} className="sub-task-status">
                    <span>
                      {subTask.microtaskId}. {subTask.title}
                    </span>
                    <span
                      className={`status-label ${
                        subTask.completionState === 1
                          ? "executed"
                          : "not-executed"
                      }`}
                    >
                      {subTask.completionState === 1
                        ? "Executed"
                        : "Not Executed"}
                    </span>
                  </div>
                ))}
              </div>
              <div className="comment-section">
                <h4>
                  What Happened in More Details (in terms of events not result)
                </h4>
                <textarea
                  value={comment}
                  onChange={handleCommentChange}
                  placeholder="Add your comments here..."
                ></textarea>
              </div>
              <div className="evaluation-section">
                <h4>Success Evaluation</h4>
                <label>
                  <input
                    type="radio"
                    value="Failure"
                    checked={evaluation === "Failure"}
                    onChange={handleEvaluationChange}
                  />
                  Failure
                </label>
                <label>
                  <input
                    type="radio"
                    value="Significant Deviation"
                    checked={evaluation === "Significant Deviation"}
                    onChange={handleEvaluationChange}
                  />
                  Significant Deviation
                </label>
                <label>
                  <input
                    type="radio"
                    value="Minor Deviation"
                    checked={evaluation === "Minor Deviation"}
                    onChange={handleEvaluationChange}
                  />
                  Minor Deviation
                </label>
                <label>
                  <input
                    type="radio"
                    value="Success"
                    checked={evaluation === "Success"}
                    onChange={handleEvaluationChange}
                  />
                  Success
                </label>
              </div>
              <button
                className="final-complete-button"
                onClick={() => handleFinalCompleteClick(step.stepId)}
                disabled={isCompleteButtonDisabled}
              >
                Complete
              </button>
            </div>
          )}
        </div>
      ))}
      {/* Add the Complete button here at the bottom */}
      <button
        className={`complete-process-button ${
          isProcessCompleted ? "button-disabled" : ""
        }`}
        onClick={handleCompleteProcess}
        disabled={isProcessCompleted}
      >
        Complete
      </button>
    </div>
  );
};

export default ProcessSteps;
